import { LabwareTypeInfo } from "features/WorklistTools/shared/interfaces";
import React from "react";
import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import { AccordionPanel } from "./AccordionPanel";
import { DestinationWellMapping } from "./DestinationWellMapping/DestinationWellMapping";
import { MethodSettings } from "./MethodSettings/MethodSettings";
import { PooledWellSamples } from "./PooledWellSamples/PooledWellSamples";
import { SeedingPriority } from "./SeedingPriority/SeedingPriority";
import { HarvestWellsMapping } from "./WellMapping/HarvestWellsMapping";
import { Int1ToInt3WellsMapping } from "./WellMapping/IntOneToIntThreeMapping";
import { Int1ToInt2WellsMapping } from "./WellMapping/IntOneToIntTwoMapping";
import { Int2ToInt3WellsMapping } from "./WellMapping/IntTwoToIntThreeMapping";

interface PoolingNormalizationToolSidebarProps {
  deadPlateInfo: LabwareTypeInfo[] | undefined;
}

const StyledSidebarContainer = styled.div`
  padding: 20px;
  height: 100%;
  overflow: auto;
  width: 100%;
  resize: horizontal;
`;

export const PoolingNormalizationToolSidebar = ({
  deadPlateInfo,
}: PoolingNormalizationToolSidebarProps) => {
  return (
    <StyledSidebarContainer>
      <AccordionPanel
        id="method-settings-panel"
        panel="method-settings"
        title="Method Settings"
      >
        <MethodSettings deadPlateTypeInfo={deadPlateInfo} />
      </AccordionPanel>

      <AccordionPanel
        id="seeding-priority-panel"
        panel="seeding-priority"
        title="Stamp/Single Channel Seed Priority"
      >
        <SeedingPriority />
      </AccordionPanel>

      <AccordionPanel
        id="harvest-wells-mapping-panel"
        panel="harvest-wells"
        title="Harvest Wells"
      >
        <HarvestWellsMapping />
      </AccordionPanel>

      <AccordionPanel
        id="int1-to-int2-mapping-panel"
        panel="int1ToInt2-wells"
        title="Int 1 To Int 2"
      >
        <Int1ToInt2WellsMapping />
      </AccordionPanel>

      <AccordionPanel
        id="int2-to-int3-mapping-panel"
        panel="int2ToInt3-wells"
        title="Int 2 To Int 3"
      >
        <Int2ToInt3WellsMapping />
      </AccordionPanel>

      <AccordionPanel
        id="int1-to-int3-mapping-panel"
        panel="int1ToInt3-wells"
        title="Int 1 To Int 3"
      >
        <Int1ToInt3WellsMapping />
      </AccordionPanel>

      <AccordionPanel
        id="destination-well-mapping-panel"
        panel="destination-well-mapping"
        title="Destination Well Mapping"
      >
        <DestinationWellMapping />
      </AccordionPanel>

      <AccordionPanel
        id="pooled-well-samples-panel"
        panel="pooled-well-samples"
        title="Pooled Well Samples"
      >
        <PooledWellSamples />
      </AccordionPanel>
    </StyledSidebarContainer>
  );
};
