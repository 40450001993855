import { allElementsAreEqual } from "helpers/array-helpers";
import { Worklist } from "../shared/interfaces";
import {
  getColIndexFromSelectedWell,
  getRowIndexFromSelectedWell,
} from "../shared/WorklistHelpers";
import {
  DestPlateInfoState,
  IntermediatePlateInfoState,
  IStampTopLeftWorklistState,
  SourcePlateInfoState,
  WorklistValuesState,
} from "./state";
import {
  IPoolingMethodSettings,
  IPoolingNormalizationToolInternalState,
} from "./state/initial-state";

export enum CellNumberUpdateType {
  ALL = "All",
  EMPTY = "Empty",
  INDIVIDUAL = "Individual",
}

export const containsCrossContamination = (
  worklistValues: WorklistValuesState
) => {
  //check if all source wells are equal in each intermediate stamp
  const stampTopLeftKeys = [
    "int1StampTopLeft",
    "int2StampTopLeft",
    "int3StampTopLeft",
  ];
  const worklistKeys = Object.keys(worklistValues);
  for (const key of worklistKeys) {
    if (!stampTopLeftKeys.includes(key)) continue;

    const extractSourceWells = (entry: IStampTopLeftWorklistState[]) => {
      return entry.map((well) => {
        return well.sourceWellId;
      });
    };

    const stampedSourceWells = extractSourceWells(
      worklistValues[key as keyof WorklistValuesState]
    );

    if (stampedSourceWells.length && !allElementsAreEqual(stampedSourceWells)) {
      return true;
    }
  }

  return false;
};

export const containsStampAndSingleChannelSeeding = (worklistValues: any) => {
  return (
    (worklistValues.int1ToDest.length ||
      worklistValues.int2ToDest.length ||
      worklistValues.int3ToDest.length) &&
    (worklistValues.int1StampTopLeft.length ||
      worklistValues.int2StampTopLeft.length ||
      worklistValues.int3StampTopLeft.length)
  );
};

export const containsStamp = (worklistValues: WorklistValuesState) => {
  return (
    worklistValues.int1StampTopLeft.length ||
    worklistValues.int2StampTopLeft.length ||
    worklistValues.int3StampTopLeft.length
  );
};

export const containsSingleChannelSeeding = (
  worklistValues: WorklistValuesState
) => {
  return (
    worklistValues.int1ToDest.length ||
    worklistValues.int2ToDest.length ||
    worklistValues.int3ToDest.length
  );
};

export const getWorklistRows = (worklistValues: any, destIntIndex: number) => {
  const worklistRows = [];
  switch (destIntIndex) {
    case 1:
      return worklistValues.int1ToInt2;
    case 2:
      let worklistRows = [];
      worklistRows = [
        ...worklistValues.int1ToInt3,
        ...worklistValues.int2ToInt3,
      ];
      return worklistRows;
    default:
      return worklistValues.harvestWells;
  }
};

export const buildHarvestWellsWorklist = (
  harvestWells: any[],
  sourcePlateInfo: any[],
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];
  for (const row of harvestWells) {
    const currentSourcePlate = sourcePlateInfo[row.sourcePlateIndex];
    const currentDestPlate = destPlateInfo[row.destPlateIndex];
    const index = currentSourcePlate.wellInfo.findIndex(
      (e: any) =>
        e.rowPos - 1 === getRowIndexFromSelectedWell(row.sourceWellId) &&
        e.colPos - 1 === getColIndexFromSelectedWell(row.sourceWellId)
    );
    if (index > -1)
      worklist.push({
        task: "HarvestWells",
        details: "",
        aspirateVol: "",
        dispenseVol: "",
        transferVol: row.transferVol.toString(),
        sourcePlateType: currentSourcePlate.labwareTypeCode,
        sourcePlateBarcode: currentSourcePlate.plateBarcode,
        sourceWellID: row.sourceWellId,
        destinationPlateType: currentDestPlate.labwareTypeCode,
        destinationPlateBarcode: currentDestPlate.plateBarcode,
        destinationWellID: row.destWellId,
      });
  }
  return worklist;
};

export const buildInt1ToInt2Worklist = (
  int1ToInt2: any[],
  sourcePlateInfo: any[],
  intPlateInfo: any[],
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];

  for (const row of int1ToInt2) {
    worklist.push({
      task: "Int1ToInt2",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[0].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[0].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: intPlateInfo[1].labwareTypeCode,
      destinationPlateBarcode: intPlateInfo[1].plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt2ToInt3Worklist = (
  int1ToInt2: any[],
  sourcePlateInfo: any[],
  intPlateInfo: any[],
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];

  for (const row of int1ToInt2) {
    worklist.push({
      task: "Int2ToInt3",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[1].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[1].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: intPlateInfo[2].labwareTypeCode,
      destinationPlateBarcode: intPlateInfo[2].plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt1ToInt3Worklist = (
  int1ToInt3: any[],
  sourcePlateInfo: any[],
  intPlateInfo: any,
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];

  for (const row of int1ToInt3) {
    worklist.push({
      task: "Int1ToInt3",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[0].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[0].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: intPlateInfo[2].labwareTypeCode,
      destinationPlateBarcode: intPlateInfo[2].plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt1StampTopLeftWorklist = (
  int1StampTopLeft: any[],
  intPlateInfo: any[],
  destPlateInfo: any[]
) => {
  const worklist: any[] = [];
  for (const row of int1StampTopLeft) {
    const currentDestPlateInfo = destPlateInfo[row.destPlateIndex];
    worklist.push({
      task: "Int1StampTopLeft",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: "",
      sourcePlateType: intPlateInfo[0].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[0].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: currentDestPlateInfo.labwareTypeCode,
      destinationPlateBarcode: currentDestPlateInfo.plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt2StampTopLeftWorklist = (
  int2StampTopLeft: any[],
  intPlateInfo: any[],
  destPlateInfo: any[]
) => {
  const worklist: any[] = [];
  for (const row of int2StampTopLeft) {
    const currentDestPlateInfo = destPlateInfo[row.destPlateIndex];
    worklist.push({
      task: "Int2StampTopLeft",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: "",
      sourcePlateType: intPlateInfo[1].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[1].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: currentDestPlateInfo.labwareTypeCode,
      destinationPlateBarcode: currentDestPlateInfo.plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt3StampTopLeftWorklist = (
  int3StampTopLeft: any[],
  intPlateInfo: any[],
  destPlateInfo: any[]
) => {
  const worklist: any[] = [];
  for (const row of int3StampTopLeft) {
    const currentDestPlateInfo = destPlateInfo[row.destPlateIndex];
    worklist.push({
      task: "Int3StampTopLeft",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: "",
      sourcePlateType: intPlateInfo[2].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[2].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: currentDestPlateInfo.labwareTypeCode,
      destinationPlateBarcode: currentDestPlateInfo.plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt1ToDestWorklist = (
  int1ToDest: any[],
  sourcePlateInfo: any[],
  intPlateInfo: any,
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];

  for (const row of int1ToDest) {
    const destPlateIndex = destPlateInfo.findIndex(
      (item) => item.index == row.destPlateIndex
    );
    worklist.push({
      task: "Int1ToDest",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[0].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[0].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: destPlateInfo[destPlateIndex].labwareTypeCode,
      destinationPlateBarcode: destPlateInfo[destPlateIndex].plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt2ToDestWorklist = (
  int2ToDest: any[],
  sourcePlateInfo: any[],
  intPlateInfo: any,
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];

  for(const row of int2ToDest) {
    const destPlateIndex = destPlateInfo.findIndex(
      (item) => item.index == row.destPlateIndex
    );
    worklist.push({
      task: "Int2ToDest",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[1].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[1].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: destPlateInfo[destPlateIndex].labwareTypeCode,
      destinationPlateBarcode: destPlateInfo[destPlateIndex].plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildInt3ToDestWorklist = (
  int3ToDest: any[],
  sourcePlateInfo: any[],
  intPlateInfo: any,
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];

  for(const row of int3ToDest) {
    const destPlateIndex = destPlateInfo.findIndex(
      (item) => item.index == row.destPlateIndex
    );
    worklist.push({
      task: "Int3ToDest",
      details: "",
      aspirateVol: "",
      dispenseVol: "",
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[2].labwareTypeCode,
      sourcePlateBarcode: intPlateInfo[2].plateBarcode,
      sourceWellID: row.sourceWellId,
      destinationPlateType: destPlateInfo[destPlateIndex].labwareTypeCode,
      destinationPlateBarcode: destPlateInfo[destPlateIndex].plateBarcode,
      destinationWellID: row.destWellId,
    });
  }
  return worklist;
};

export const buildReferenceStampShape = (stampWells: string[]) => {
  const worklist: Worklist[] = [];
  const isConsecutive = (currentWellId: string, nextWellId: string) => {
    let isSameRow = false;
    let isSameCol = false;
    if (currentWellId.charCodeAt(0) === nextWellId.charCodeAt(0))
      isSameRow = true;
    if (
      parseInt(nextWellId.substring(1)) -
        parseInt(currentWellId.substring(1)) ===
      1
    )
      isSameCol = true;
    return isSameRow && isSameCol;
  };

  let referenceStampShape = "";
  let isCountingConsecutives = false;

  for (let i = 0; i < stampWells.length; i++) {
    if (i + 1 > stampWells.length - 1) {
      referenceStampShape += `${stampWells[i]};`;
      continue;
    }
    const wellsAreConsecutive = isConsecutive(stampWells[i], stampWells[i + 1]);
    if (wellsAreConsecutive && !isCountingConsecutives) {
      referenceStampShape += `${stampWells[i]}:`;
      isCountingConsecutives = true;
    } else if (wellsAreConsecutive) {
      continue;
    } else {
      isCountingConsecutives = false;
      referenceStampShape += `${stampWells[i]};`;
    }
  }
  if (stampWells.length > 0) {
    worklist.push({
      task: "ReferenceStampShape",
      sourceWellID: referenceStampShape,
    });
  }

  return worklist;
};

export const buildOperatingVolumeWorklist = (
  sourcePlateInfo: SourcePlateInfoState[],
  destPlateInfo: DestPlateInfoState[]
) => {
  const worklist: Worklist[] = [];
  for (let i = 0; i < sourcePlateInfo.length; i++) {
    if (sourcePlateInfo[i].plateBarcode != "") {
      worklist.push({
        task: "SourceLabwareOperating",
        details: sourcePlateInfo[i].operatingVol.toString() ?? "",
        destinationPlateType: sourcePlateInfo[i].labwareTypeCode,
      });
    }
  }

  for (let i = 0; i < destPlateInfo.length; i++) {
    if (destPlateInfo[i].plateBarcode != "") {
      worklist.push({
        task: "DestinationLabwareOperating",
        details: destPlateInfo[i].operatingVol.toString(),
        aspirateVol: (+destPlateInfo[i].preprocess).toString(),
        dispenseVol: (+destPlateInfo[i].topup).toString(),
        transferVol: destPlateInfo[i].startingVol.toString(),
        destinationPlateType: destPlateInfo[i].labwareTypeCode,
        destinationPlateBarcode: destPlateInfo[i].plateBarcode,
      });
    }
  }
  return worklist;
};

export const buildSeedingPriorityWorklist = (
  groupedSeedingPriority: { id: string; content: string }[],
  seedingPriority_12_24_Well: string[],
  seedingPriority_96_384_Well: string[]
) => {
  const worklist: Worklist[] = [];
  let priorityValue = 1;

  for (const row of groupedSeedingPriority) {
    if (row.id === "12/24W") {
      for (const labwareTypeCode of seedingPriority_12_24_Well) {
        worklist.push({
          task: "SeedingPriority",
          details: priorityValue.toString(),
          aspirateVol: "0",
          destinationPlateType: labwareTypeCode,
        });
        priorityValue++;
      }
    } else if (row.id === "96/384W") {
      for (const labwareTypeCode of seedingPriority_96_384_Well) {
        worklist.push({
          task: "SeedingPriority",
          details: priorityValue.toString(),
          aspirateVol: "0",
          destinationPlateType: labwareTypeCode,
        });
        priorityValue++;
      }
    }
  }
  return worklist;
};

export const buildMethodSettingsWorklist = (
  poolingState: IPoolingNormalizationToolInternalState,
  buildToDownload = true
) => {
  const methodSettings = poolingState.methodSettings;
  const worklist: Worklist[] = [];

  worklist.push({
    task: "IncubationTime",
    details: buildToDownload
      ? (methodSettings.dissociationTime * 60).toString()
      : methodSettings.dissociationTime.toString(),
  });
  worklist.push({
    task: "SpinParameters",
    details: methodSettings.spinParamPercent.toString(),
    aspirateVol: buildToDownload
      ? (methodSettings.spinTime * 60).toString()
      : methodSettings.spinTime.toString(),
    dispenseVol: methodSettings.spinAccel.toString(),
    transferVol: methodSettings.spinDecel.toString(),
  });
  // Remove comments to add Source96HeadProcessing Logic
  // worklist.push({
  //   task: "Source96HeadProcessing",
  //   details: (+methodSettings.source96HeadProcessing).toString(),
  // });
  worklist.push({
    task: "Int196HeadProcessing",
    details: (+methodSettings.int196HeadProcessing).toString(),
  });
  worklist.push({
    task: "ReducedVolWellWash",
    details: (+methodSettings.reducedVolWellWash).toString(),
  });
  worklist.push({
    task: "ReducedPelResusSpeed",
    details: (+methodSettings.reducedPelResusSpeed).toString(),
  })
  worklist.push({
    task: "ExtraPelletWash",
    details: (+methodSettings.extraPelletWash).toString(),
  });
  worklist.push({
    task: "Dissociation",
    details: (+methodSettings.dissociation).toString(),
  });
  worklist.push({
    task: "DissociationWash",
    details: (+methodSettings.washBeforeDissociation).toString(),
  });
  worklist.push({
    task: "DissociationWashRGT",
    details: methodSettings.dissociationWashRGT.toString(),
  });
  worklist.push({
    task: "HarvestWash",
    details: (+methodSettings.washAfterDissociation).toString(),
  });
  worklist.push({
    task: "HarvestWashRGT",
    details: methodSettings.harvestWashRGT.toString(),
  });
  worklist.push({
    task: "ReFeedWells",
    details: (+methodSettings.reFeedSourceWells).toString(),
  });
  worklist.push({
    task: "DiscardSourcePlate",
    details: (+methodSettings.discardSourcePlate).toString(),
  });
  worklist.push({
    task: "ReFeedWellsRGT",
    details: methodSettings.reFeedWellsRGT.toString(),
  });
  worklist.push({
    task: "Int1SpinResuspend",
    details: methodSettings.int1SpinResuspend.toString(),
  });
  worklist.push({
    task: "Int1CountAliquot",
    details: methodSettings.int1CountAliquot.toString(),
  });
  worklist.push({
    task: "StampReuseTips",
    details: (+(methodSettings.stampReuseTips ?? false)).toString(),
  });
  worklist.push({
    task: "StampVolume",
    details: methodSettings.stampVolume.toString(),
  });
  return worklist;
};

export const buildAuxPlateBarcodeWorklist = (
  intPlateInfo: IntermediatePlateInfoState[],
  deadPlateBarcode: string,
  deadPlateType: string,
  methodSettings: IPoolingMethodSettings
) => {
  const worklist: Worklist[] = [];
  for (const [index, plate] of intPlateInfo.entries()) {
    if (plate.labwareTypeCode !== "") {
      worklist.push({
        task: `Intermediate${index + 1}Resuspension`,
        details: plate.resuspensionVol.toString(),
        sourcePlateBarcode: plate.plateBarcode,
      });
    }
  }
  worklist.push({
    task: "DeadTotalPlate",
    details: methodSettings.numberOfAliquots.toString(),
    transferVol: methodSettings.aliquotVolume.toString(),
    sourcePlateType: deadPlateType,
    sourcePlateBarcode: deadPlateBarcode,
  });

  return worklist;
};

export const buildMappingFileWorklist = (
  sourcePlateInfo: SourcePlateInfoState[],
  intPlateInfo: IntermediatePlateInfoState[],
  destPlateInfo: DestPlateInfoState[]
) => {
  const worklist: Worklist[] = [];
  const uniqueSourcePlateTypes = [
    ...new Set(
      sourcePlateInfo
        .map((e) => e.labwareTypeCode)
        .filter((labware) => labware !== "")
    ),
  ];
  const uniqueDestPlateTypes = [
    ...new Set(
      destPlateInfo
        .map((e) => e.labwareTypeCode)
        .filter((labware) => labware !== "")
    ),
  ];
  const uniqueIntPlatesTypes = [
    ...new Set(intPlateInfo.map((e) => e.labwareTypeCode)),
  ];

  const containsIntermediate2Plate = intPlateInfo[1].plateBarcode !== "";
  const containsIntermediate3Plate = intPlateInfo[2].plateBarcode !== "";

  const srcPlateCode =
    uniqueSourcePlateTypes.length > 1
      ? "X"
      : sourcePlateInfo[0].plateBarcode.split("_")[1];
  const destPlateCode =
    uniqueDestPlateTypes.length > 1
      ? "Y"
      : destPlateInfo[0].plateBarcode.split("_")[1];
  //TODO: figure out how to handle multiple int plate types
  const intPlateCode = "Int" + srcPlateCode + "-" + destPlateCode;
  const deadPlateCode = "Dead" + srcPlateCode + "-" + destPlateCode;
  worklist.push({
    task: "SrcToIntMappingFileKeyword",
    sourcePlateBarcode: "_" + srcPlateCode + "_to_" + intPlateCode + "_",
  });
  if (containsIntermediate2Plate) {
    worklist.push({
      task: "Int1ToInt2MappingFileKeyword",
      sourcePlateBarcode: "_" + intPlateCode + "_to_" + intPlateCode + "_",
    });
  }
  if (containsIntermediate3Plate) {
    worklist.push({
      task: "Int12ToInt3MappingFileKeyword",
      sourcePlateBarcode: "_" + intPlateCode + "_to_" + intPlateCode + "_",
    });
  }
  worklist.push({
    task: "IntToDeadMappingFileKeyword",
    sourcePlateBarcode: "_" + intPlateCode + "_to_" + deadPlateCode + "_",
  });
  worklist.push({
    task: "IntToDestMappingFileKeyword",
    sourcePlateBarcode: "_" + intPlateCode + "_to_" + destPlateCode + "_",
  });

  return worklist;
};
