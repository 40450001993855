import { gql } from "graphql-request";

export const IncomingSampleType = gql`
  fragment IncomingSampleType on CollectionSampleType {
    incomingSampleTypeId
    sampleTypeId
    sampleType {
      sampleTypeId
      sampleTypeString
    }
    dateToProcess
    isActive
    notes
  }
`;
export const IncomingSamplesTaskTableEntry = gql`
  fragment IncomingSampleTaskTableEntry on CollectionTask {
    incomingSampleType {
      sampleType {
        sampleTypeId
        sampleTypeString
      }
      collection {
        collectionId
        project {
          projectId
          projectName
        }
      }
    }
    incomingSampleTaskId
    task
    startTimeScheduled
    status
    groupAssignedTo
    userAssignedTo
    systemAssignedTo
  }
`;

export const IncomingSamplesTask = gql`
  fragment IncomingSampleTask on CollectionTask {
    ...IncomingSampleTaskTableEntry
    completedBy
    completedByTime
    endTimeActual
    endTimeScheduled
    startTimeActual
  }
  ${IncomingSamplesTaskTableEntry}
`;
export const IncomingSamplesCollection = gql`
  fragment IncomingSampleCollection on Collection {
    collectionId
    collectionSampleTypes {
      ...IncomingSampleType
    }
    createdBy
    projectId
    project {
      projectId
      projectName
      nyscfprefix
    }
    dateTimeOfArrival
    dateTimeOfCollection
    dateTimeOfArrival
  }
  ${IncomingSampleType}
`;
