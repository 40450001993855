import { LabwareTypeInfo } from "features/WorklistTools/shared/interfaces";
import React from "react";
import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import { AccordionPanel } from "./AccordionPanel";
import { MethodSettings } from "./MethodSettings/MethodSettings";
import { HarvestWellsMapping } from "./WellMapping/HarvestWellsMapping";

interface Twelve24TCToolSidebarProps {
  deadPlateInfo: LabwareTypeInfo[] | undefined;
}

const StyledSidebarContainer = styled.div`
  padding: 20px;
  height: 100%;
  overflow: auto;
  width: 100%;
  resize: horizontal;
`;

export const Twelve24TCToolSidebar = ({
  deadPlateInfo,
}: Twelve24TCToolSidebarProps) => {
  return (
    <StyledSidebarContainer>
      <AccordionPanel
        id="method-settings-panel"
        panel="method-settings"
        title="Method Settings"
      >
        <MethodSettings deadPlateTypeInfo={deadPlateInfo} />
      </AccordionPanel>
  
      <AccordionPanel
        id="harvest-wells-mapping-panel"
        panel="harvest-wells"
        title="Harvest Wells"
      >
        <HarvestWellsMapping />
      </AccordionPanel>
     
    </StyledSidebarContainer>
  );
};
