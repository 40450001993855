import { FileCopy } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Abbreviate } from "helpers/string-helpers";
import produce from "immer";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

export const AttachmentsArea = styled.div`
  border-radius: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: dashed 1px
    ${({
      // TODO: add props https://styled-components.com/docs/basics#adapting-based-on-props
      theme: {
        palette: { mode },
      },
    }) => (mode === "dark" ? "rgba(255,255,255,0.2)" : "rgba(0, 0, 0, 0.2)")};
`;

export const AttachmentAreaText = styled.div`
  font-weight: bold;
  &&:hover {
    cursor: pointer;
  }
`;

export const AttachmentsList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
`;

export interface DropZoneProps {
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  fileTypes?: string | string[];
}

export const DropZone = (props: DropZoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.fileTypes,
    onDrop: async (acceptedFiles) => {
      props.setAttachments(
        produce(props.attachments, (draft) => {
          return draft.concat(acceptedFiles);
        })
      );
    },
  });

  return (
    <AttachmentsArea {...getRootProps()}>
      <AttachmentsList>
        {props.attachments.map((file, index) => (
          <Chip
            key={file.lastModified}
            color={"primary"}
            icon={<FileCopy />}
            label={Abbreviate(file.name, 15)}
            onDelete={() =>
              props.setAttachments(
                produce(props.attachments, (draft) => {
                  draft.splice(index, 1);
                })
              )
            }
          />
        ))}
      </AttachmentsList>
      <input {...getInputProps()} />
      {isDragActive ? (
        <AttachmentAreaText>Drop attachments here ...</AttachmentAreaText>
      ) : (
        <AttachmentAreaText>
          Drop attachments, or click to select files
        </AttachmentAreaText>
      )}
    </AttachmentsArea>
  );
};
