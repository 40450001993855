import { Worklist } from "../shared/interfaces";
import {
  getColIndexFromSelectedWell,
  getRowIndexFromSelectedWell,
} from "../shared/WorklistHelpers";
import {
  DestPlateInfoState,
  SourcePlateInfoState,
  WorklistValuesState,
} from "./state";
import {
  ITwelve24TCMethodSettings,
  ITwelve24TCToolInternalState,
} from "./state/initial-state";

export enum CellNumberUpdateType {
  ALL = "All",
  EMPTY = "Empty",
  INDIVIDUAL = "Individual",
}

export const buildHarvestWellsWorklist = (
  harvestWells: any[],
  sourcePlateInfo: any[],
  destPlateInfo: any[],
  methodSettings: any
) => {
  const worklist: any[] = [];
  for (const row of harvestWells) {
    const currentSourcePlate = sourcePlateInfo[row.sourcePlateIndex];
    const currentDestPlate = destPlateInfo[row.destPlateIndex];
    const index = currentSourcePlate.wellInfo.findIndex(
      (e: any) =>
        e.rowPos - 1 === getRowIndexFromSelectedWell(row.sourceWellId) &&
        e.colPos - 1 === getColIndexFromSelectedWell(row.sourceWellId)
    );
    if (index > -1)
      worklist.push({
        task: "HarvestWells",
        details: "",
        aspirateVol: "",
        dispenseVol: "",
        transferVol: row.transferVol.toString(),
        sourcePlateType: currentSourcePlate.labwareTypeCode,
        sourcePlateBarcode: currentSourcePlate.plateBarcode,
        sourceWellID: row.sourceWellId,
        destinationPlateType: currentDestPlate.labwareTypeCode,
        destinationPlateBarcode: currentDestPlate.plateBarcode,
        destinationWellID: row.destWellId,
      });
  }
  return worklist;
};

export const buildDiscardSourcePlateWorklist = (
  sourcePlateInfo: SourcePlateInfoState[]
) => {
  const worklist: Worklist[] = [];
  for (let i = 0; i < sourcePlateInfo.length; i++) {
    if (sourcePlateInfo[i].plateBarcode !== "") {
      worklist.push({
        task: "DiscardSourcePlate",
        details: sourcePlateInfo[i].discardSourcePlate.toString() ?? "",
        sourcePlateType: sourcePlateInfo[i].labwareTypeCode,
        sourcePlateBarcode: sourcePlateInfo[i].plateBarcode,
      });
    }
  }
  return worklist;
};

export const buildOperatingVolumeWorklist = (
  destPlateInfo: DestPlateInfoState[]
) => {
  const worklist: Worklist[] = [];
  for (let i = 0; i < destPlateInfo.length; i++) {
    if (destPlateInfo[i].plateBarcode !== "") {
      worklist.push({
        task: "DestinationLabwareOperating",
        details: destPlateInfo[i].operatingVol.toString(),
        aspirateVol: (+destPlateInfo[i].preprocess).toString(),
        transferVol: destPlateInfo[i].startingVol.toString(),
        destinationPlateType: destPlateInfo[i].labwareTypeCode,
        destinationPlateBarcode: destPlateInfo[i].plateBarcode,
      });
    }
  }
  return worklist;
};

export const buildMethodSettingsWorklist = (
  twelve24TCState: ITwelve24TCToolInternalState,
  worklistValues: WorklistValuesState,
  buildToDownload = true
) => {
  const methodSettings = twelve24TCState.methodSettings;
  const worklist: Worklist[] = [];

  worklist.push({
    task: "IncubationTime",
    details:
      buildToDownload && !methodSettings.dissociationTime
        ? ""
        : buildToDownload && methodSettings.dissociationTime
        ? (methodSettings.dissociationTime * 60).toString()
        : !buildToDownload
        ? methodSettings.dissociationTime?.toString()
        : methodSettings.dissociationTime?.toString(),
  });
  worklist.push({
    task: "SpinParameters",
    details: methodSettings.spinParamPercent.toString(),
    aspirateVol: buildToDownload
      ? (methodSettings.spinTime * 60).toString()
      : methodSettings.spinTime.toString(),
    dispenseVol: methodSettings.spinAccel.toString(),
    transferVol: methodSettings.spinDecel.toString(),
  });

  worklist.push({
    task: "Dissociation",
    details: (+methodSettings.dissociation).toString(),
  });
  worklist.push({
    task: "DissociationWash",
    details: (+methodSettings.washBeforeDissociation).toString(),
  });
  worklist.push({
    task: "DissociationWashRGT",
    details: methodSettings.dissociationWashRGT.toString(),
  });
  worklist.push({
    task: "PelletResuspensionRGT",
    details: methodSettings.pelletResuspensionRGT.toString(),
  });
  worklist.push({
    task: "PelletResuspensionVolume",
    details:
      methodSettings.pelletResuspensionVol === null
        ? "0"
        : methodSettings.pelletResuspensionVol!.toString(),
  });
  worklist.push({
    task: "ManualSpin",
    details: (+userShouldManuallySpin(worklistValues)).toString(),
  });
  return worklist;
};

export const buildTopUpPostSplitWorklist = (
  destPlateInfo: DestPlateInfoState[]
) => {
  const worklist: Worklist[] = [];
  worklist.push({
    task: "TopUp",
    details: destPlateInfo[0].topupVol.toString(),
  });
  return worklist;
};

export const buildAuxPlateBarcodeWorklist = (
  intPlateInfo: SourcePlateInfoState[],
  deadPlateBarcode: string,
  deadPlateType: string,
  methodSettings: ITwelve24TCMethodSettings
) => {
  const worklist: Worklist[] = [];
  worklist.push({
    task: "DeadTotalPlate",
    details: "",
    transferVol: methodSettings.aliquotVolume.toString(),
    sourcePlateType: deadPlateType,
    sourcePlateBarcode: deadPlateBarcode,
  });

  return worklist;
};

export const buildMappingFileWorklist = (
  sourcePlateInfo: SourcePlateInfoState[],
  destPlateInfo: DestPlateInfoState[]
) => {
  const worklist: Worklist[] = [];
  const uniqueSourcePlateTypes = [
    ...new Set(
      sourcePlateInfo
        .map((e) => e.labwareTypeCode)
        .filter((labware) => labware !== "")
    ),
  ];
  const uniqueDestPlateTypes = [
    ...new Set(
      destPlateInfo
        .map((e) => e.labwareTypeCode)
        .filter((labware) => labware !== "")
    ),
  ];

  const srcPlateCode =
    uniqueSourcePlateTypes.length > 1
      ? "X"
      : sourcePlateInfo[0].plateBarcode.split("_")[1];
  const destPlateCode =
    uniqueDestPlateTypes.length > 1
      ? "Y"
      : destPlateInfo[0].plateBarcode.split("_")[1];
  const deadPlateCode = "Dead" + srcPlateCode + "-" + destPlateCode;
  worklist.push({
    task: "SrcToDestMappingFileKeyword",
    sourcePlateBarcode: "_" + srcPlateCode + "_to_" + destPlateCode + "_",
  });

  worklist.push({
    task: "DestToDestMappingFileKeyword",
    sourcePlateBarcode: "_" + destPlateCode + "_to_" + destPlateCode + "_",
  });

  return worklist;
};

export const getNumberOfAdditionalTubes = (
  worklistValues: WorklistValuesState
) => {
  const harvestWells = worklistValues.harvestWells;
  const existingSourceWells = new Set<string>();
  let additionalTubeCount = 0;
  for (const row of harvestWells) {
    const sourceIndexAndWellId = `${row.sourcePlateIndex}${row.sourceWellId}`;
    if (existingSourceWells.has(sourceIndexAndWellId)) {
      additionalTubeCount++;
    } else {
      existingSourceWells.add(row.sourcePlateIndex + row.sourceWellId);
    }
  }
  return additionalTubeCount;
};

export const prvDividedByMaxDestWellDict = (
  worklistValues: any,
  methodSettings: any
) => {
  const srcWellIDDict: Map<string, [number, number]> = new Map();
  if (worklistValues!.harvestWells!) {
    for (const harvestWellIndex in worklistValues.harvestWells) {
      const srcWellIDKey =
        worklistValues.harvestWells[harvestWellIndex].sourceWellId;
      const srcWellIDVals = srcWellIDDict.get(srcWellIDKey!)!;
      if (srcWellIDDict.get(srcWellIDKey)) {
        srcWellIDDict.set(srcWellIDKey, [
          srcWellIDVals[0] + 1,
          methodSettings.pelletResuspensionVol! / (srcWellIDVals[0] + 1),
        ]);
      }

      if (!srcWellIDDict.get(srcWellIDKey)) {
        srcWellIDDict.set(srcWellIDKey, [
          1,
          methodSettings.pelletResuspensionVol!,
        ]);
      }
    }
  }
  return srcWellIDDict;
};

export const errorPRVDivDestWell = (
  worklistValues: any,
  methodSettings: any
) => {
  const prvDivByMaxDestDict = prvDividedByMaxDestWellDict(
    worklistValues,
    methodSettings
  );
  for (const harvestWellIndex in worklistValues.harvestWells) {
    const srcWellIDKey =
      worklistValues.harvestWells[harvestWellIndex].sourceWellId;

    if (
      prvDivByMaxDestDict.get(srcWellIDKey) &&
      prvDivByMaxDestDict.get(srcWellIDKey!)![1] !== 0 &&
      prvDivByMaxDestDict.get(srcWellIDKey!)![1] < 20
    ) {
      return "You are splitting across wells resulting in transfers of less than 20 µL. \
            Please remove splits or increase resuspension volume.";
    }
  }
  return "";
};

export const userShouldManuallySpin = (worklistValues: any) => {
  const additionalTubes = getNumberOfAdditionalTubes(worklistValues);
  if (additionalTubes >= 10) return true;
  return false;
};

export const topupCalc = (
  worklistValues: any,
  methodSettings: any,
  destPlateInfo: DestPlateInfoState[]
) => {
  const prvDivByMaxDestWellDict = prvDividedByMaxDestWellDict(
    worklistValues,
    methodSettings
  );
  const numOfTotalDestWells: number = worklistValues.harvestWells!.length;
  if (
    numOfTotalDestWells === 0 &&
    destPlateInfo[0]!.topupVol < methodSettings.pelletResuspensionVol &&
    destPlateInfo[0]!.topupVol > 0
  )
    return "The final top up volume cannot be less than the split volume. Please check your splits or increase your final top up volume.";

  let highestSplit = -1;
  for (const [key, val] of prvDivByMaxDestWellDict) {
    if (val[1] > highestSplit) {
      highestSplit = val[1];
      if (
        !isNaN(destPlateInfo[0].topupVol!) ||
        destPlateInfo[0].topupVol! != undefined
      ) {
        if (
          destPlateInfo[0]!.topupVol > 0 &&
          destPlateInfo[0]!.topupVol < highestSplit
        ) {
          return "The final top up volume cannot be less than the split volume. Please check your splits or increase your final top up volume.";
        }
      }
    }
  }
  return "";
};
