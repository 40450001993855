import { AppBar } from "@mui/material";
import { AccountNavItem } from "features/Navbar/AccountNavItem";
import { NavItems } from "features/Navbar/nav-items";
import { NavItem } from "features/Navbar/NavItem";
import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { StyledLink } from "shared-components/styled-components";
import styled from "styled-components";

const DefaultContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;
const Logo = styled.div`
  font-size: 2rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: lighter;
`;

export function Nav({ children }: Wrapper): JSX.Element {
  return (
    <AppBar position={"static"} color={"primary"}>
      <Container>
        <StyledLink to={"/"}>
          <Logo>NYSCF</Logo>
        </StyledLink>
        <DefaultContent>
          {NavItems.map((item, idx) => (
            <NavItem key={idx} item={item} idx={idx} />
          ))}
          <AccountNavItem />
        </DefaultContent>
      </Container>
      <div>{children}</div>
    </AppBar>
  );
}
