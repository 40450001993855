import { is384W_CCU_Plate, isMatrixRack } from "../shared/PlateHelpers";
import { checkForDuplicateBarcodes } from "../shared/WorklistHelpers";
import { getWellStampCount } from "./plates/handlers/SelectDestinationWells";
import {
  containsCrossContamination,
  containsSingleChannelSeeding,
  containsStamp,
  containsStampAndSingleChannelSeeding,
} from "./PoolingNormalizationToolHelpers";
import {
  DestPlateInfoState,
  WorklistValuesState,
  WorklistWellMappingState,
} from "./state";
import {
  IPoolingMethodSettings,
  IPoolingNormalizationToolInternalState,
} from "./state/initial-state";

export const getPoolingRequiredWorklistWarnings = (
  sourcePlateInfo: any,
  destPlateInfo: DestPlateInfoState[],
  intPlateInfo: any[],
  deadPlateType: string,
  worklistValues: any,
  internalState: IPoolingNormalizationToolInternalState
) => {
  let warning: string[] = [];
  const methodSettings = internalState.methodSettings;

  const requiredMessages = checkForRequiredMethodSettings(methodSettings);
  const vSpingRangeErrors = checkVSpinRange(methodSettings);

  if (
    methodSettings.stampReuseTips === undefined &&
    containsStamp(worklistValues)
  ) {
    requiredMessages.push("Stamp Reuse Tips is required");
  }

  warning = [...requiredMessages, ...vSpingRangeErrors];

  if (is384W_CCU_Plate(deadPlateType)) {
    const MIN_NUMBER_OF_ALIQUOTS = 1;
    const MAX_NUMBER_OF_ALIQUOTS = 4;
    if (
      methodSettings.numberOfAliquots < MIN_NUMBER_OF_ALIQUOTS ||
      methodSettings.numberOfAliquots > MAX_NUMBER_OF_ALIQUOTS
    ) {
      warning.push("Number of Aliquots is out of range");
    }

    if (methodSettings.aliquotVolume === -1) {
      warning.push("Aliquot Volume is required");
    }
  }

  //Matrix Rack Warnings
  if (
    destPlateInfo.some(
      (plate) =>
        isMatrixRack(plate.labwareTypeCode) &&
        (plate.startingVol > 500 || plate.startingVol <= 0)
    )
  ) {
    warning.push(
      "Destination Matrix Rack Resuspension Vol must be between 1 and 500"
    );
  }

  if (
    destPlateInfo.some(
      (plate) => isMatrixRack(plate.labwareTypeCode) && plate.operatingVol > 900
    )
  ) {
    warning.push("Destination Matrix Rack Operating Vol must be less than 900");
  }

  if (
    intPlateInfo.some((e) => e.labwareTypeCode !== "" && e.resuspensionVol <= 0)
  ) {
    warning.push("Intermediate Plate Resuspension Vol is Required");
  }

  //TODO: Refactor to iterate through int plates check multiple warnings;
  for (let i = 0; i < intPlateInfo.length; i++) {
    const wellsToCheck = worklistValues.stampTopLeftTransfers
      .filter((e: any) => e.sourcePlateIndex === i)
      .map((item: any) => item.sourceWellId);
    const map = getWellStampCount(wellsToCheck);
    const warningMessage =
      getWarningMessageForStampVolsGreaterThanResuspensionVols(
        i,
        map ?? [],
        methodSettings.stampVolume,
        intPlateInfo[i].resuspensionVol
      );
    if (warningMessage) warning.push(warningMessage);
  }

  for (let i = 0; i < destPlateInfo.length; i++) {
    const wellsToCheck = worklistValues.stampTopLeftTransfers
      .filter((e: any) => e.destPlateIndex === i)
      .map((item: any) => item.destWellId);

    const map = getWellStampCount(wellsToCheck);
    const warningMessage = getWarningMessageForStampVolsGreaterThanOperatingVol(
      map,
      destPlateInfo[i].operatingVol,
      destPlateInfo[i].startingVol,
      methodSettings.stampVolume
    );
    if (warningMessage) warning.push(warningMessage);
  }

  if (
    !containsStamp(worklistValues) &&
    !containsSingleChannelSeeding(worklistValues)
  ) {
    warning.push("Missing Intermediate to Destination Plate Mapping");
  }

  checkIfCellNumberIsZero(
    warning,
    worklistValues.int1ToInt2,
    worklistValues.int2ToInt3,
    worklistValues.int1ToInt3,
    worklistValues.int1ToDest,
    worklistValues.int2ToDest,
    worklistValues.int3ToDest
  );

  if (internalState.methodSettings.stampVolume > 250) {
    warning.push("Stamp Volume must be less than 250");
  }

  if (
    containsStampAndSingleChannelSeeding(worklistValues) &&
    internalState.stampSingleChannelPriority === undefined
  ) {
    warning.push("You must select Stamp or Single Channel Seed First");
  }

  if (!checkIfStampVolsAndLabwareTypesMatch(destPlateInfo)) {
    warning.push("Resolve Destination Plate Starting Vol mismatch");
  }

  if (checkForDuplicateBarcodes(sourcePlateInfo)) {
    warning.push("Duplicate Source Plate Barcodes exist");
  }

  if (checkForDuplicateBarcodes(destPlateInfo)) {
    warning.push("Duplicate Destination Plate Barcode exist");
  }

  if (deadPlateType === "") {
    warning.push("Dead Plate Type must be selected");
  }

  if (intPlateInfo[0].labwareTypeCode === "") {
    warning.push("Int1 Plate Type must be selected");
  }
  return warning;
};

export const getPoolingOptionalWorklistWarnings = (
  sourcePlateInfo: any,
  intPlateInfo: any[],
  destPlateInfo: any,
  worklistValues: WorklistValuesState,
  methodSettings: IPoolingMethodSettings
) => {
  const warning = [];

  if (
    methodSettings.stampReuseTips &&
    containsCrossContamination(worklistValues)
  ) {
    warning.push("Intermediate Stamp contains cross contamination");
  }

  if (
    methodSettings.reducedVolWellWash &&
    !methodSettings.reducedPelResusSpeed
  ) {
    warning.push(
      "Reduced Volume Well Washing is enabled, but Reduced Pellet Resuspension Mix Speed is disabled. It is recommended to enable both settings"
    );
  }

  for (let i = 0; i < intPlateInfo.length; i++) {
    const wellsToCheck = worklistValues.stampTopLeftTransfers
      .filter((e) => e.sourcePlateIndex === i)
      .map((item) => item.sourceWellId);
    const map = getWellStampCount(wellsToCheck);
    const warningMessage = getWarningMessageForStampVolsEqualToResuspensionVols(
      i,
      map ?? [],
      methodSettings.stampVolume,
      intPlateInfo[i].resuspensionVol
    );
    if (warningMessage) warning.push(warningMessage);
  }

  //TODO: Removed this when dest plate specific pre process was added. Remove permanently or keep? vvv
  // if (
  //   methodSettings.preprocessPlate &&
  //   destPlateInfo.some(
  //     (e: any) => e.startingVol === "" && e.labwareTypeCode !== ""
  //   )
  // ) {
  //   warning.push("Set Starting Vol for Seeding Priority!");
  // }

  //TODO: Removed this when dest plate specific top up was added. Remove permanently or keep? vvv
  // if (
  //   (worklistValues.int1StampTopLeft.length > 0 ||
  //     worklistValues.int2StampTopLeft.length > 0 ||
  //     worklistValues.int3StampTopLeft.length > 0) &&
  //   methodSettings.topUpDestPlates
  // ) {
  //   warning.push(
  //     "Top Up Dest Plates is enabled. It is recommended you disable this option because you are stamping"
  //   );
  // }
  if (
    destPlateInfo[0].plateBarcode.includes("LGDIF") ||
    destPlateInfo[0].plateBarcode.includes("LVDIF")
  ) {
    warning.push("Make sure stamp volume equals number of stamps + 1!");
  }
  if (methodSettings.dissociationTime < 5) {
    warning.push("Dissociation Time is less than 5 minutes");
  }
  if (methodSettings.spinParamPercent < 30) {
    warning.push("Spin Params % is less than 30");
  } else if (methodSettings.spinParamPercent > 50) {
    warning.push("Spin Params % is greater than 50");
  }
  if (methodSettings.spinTime > 8) {
    warning.push("Spin Time is greater than 8 mintues");
  }
  return warning;
};

const checkIfCellNumberIsZero = (
  warning: string[],
  int1ToInt2: WorklistWellMappingState[],
  int2ToInt3: WorklistWellMappingState[],
  int1ToInt3: WorklistWellMappingState[],
  int1ToDest: WorklistWellMappingState[],
  int2ToDest: WorklistWellMappingState[],
  int3ToDest: WorklistWellMappingState[]
) => {
  const pushWarning_onZeroTransferVol = (arr: any[], prefix: string) => {
    const zeroTransferVol = arr.some(
      (e) => e.transferVol == 0 || !e.hasOwnProperty("transferVol")
    );
    if (zeroTransferVol) warning.push(`${prefix} Cell Number cannot be 0`);
  };

  // TODO: use Humanizer.ts (https://github.com/fakoua/Humanizer.ts) to resolve parameter names to strings
  pushWarning_onZeroTransferVol(int1ToInt2, "Int1 To Int2");
  pushWarning_onZeroTransferVol(int2ToInt3, "Int2 To Int3");
  pushWarning_onZeroTransferVol(int1ToInt3, "Int1 To Int3");
  pushWarning_onZeroTransferVol(int1ToDest, "Int1 to Dest");
  pushWarning_onZeroTransferVol(int2ToDest, "Int2 to Dest");
  pushWarning_onZeroTransferVol(int3ToDest, "Int3 to Dest");

  return warning;
};

const checkIfStampVolsAndLabwareTypesMatch = (
  destPlateInfo: DestPlateInfoState[]
) => {
  if (destPlateInfo.length < 2) return true;
  const destPlateInfoCopy = [...destPlateInfo];
  const sortedByLabwareState = destPlateInfoCopy.sort((a, b) =>
    a.labwareTypeCode.toLocaleLowerCase() <
    b.labwareTypeCode.toLocaleLowerCase()
      ? -1
      : b.labwareTypeCode.toLocaleLowerCase() >
        a.labwareTypeCode.toLocaleLowerCase()
      ? 1
      : 0
  );
  for (let i = 1; i < sortedByLabwareState.length; i++) {
    if (
      sortedByLabwareState[i - 1].labwareTypeCode ===
        sortedByLabwareState[i].labwareTypeCode &&
      sortedByLabwareState[i - 1].startingVol !==
        sortedByLabwareState[i].startingVol
    )
      return false;
  }

  return true;
};

const getTotalWellVol = (wellCount: number, stampVolume: number) =>
  wellCount * stampVolume;

const getWarningMessageForStampVolsGreaterThanResuspensionVols = (
  intPlateIndex: number,
  intMap: { well: string; count: number }[],
  stampVolume: number,
  resuspensionVolume: number
) => {
  const wellsWithExceededVolume = [];

  for (const well of intMap) {
    if (getTotalWellVol(well.count, stampVolume) > resuspensionVolume) {
      wellsWithExceededVolume.push(well.well);
    }
  }

  if (wellsWithExceededVolume.length > 0) {
    return `Intermediate ${
      intPlateIndex + 1
    }'s Stamped Wells (${wellsWithExceededVolume.join(
      ", "
    )}) exceed the resuspension volume`;
  }
};

const getWarningMessageForStampVolsEqualToResuspensionVols = (
  intPlateIndex: number,
  intMap: { well: string; count: number }[],
  stampVolume: number,
  resuspensionVolume: number
) => {
  const wellsWithEqualVolume = [];
  for (const well of intMap) {
    if (getTotalWellVol(well.count, stampVolume) === resuspensionVolume) {
      wellsWithEqualVolume.push(well.well);
    }
  }

  if (wellsWithEqualVolume.length > 0) {
    return `No Dead volume is accounted for because Intermediate ${
      intPlateIndex + 1
    }'s Stamped Wells (${wellsWithEqualVolume.join(
      ", "
    )}) equal the resuspension volume`;
  }
};

const getWarningMessageForStampVolsGreaterThanOperatingVol = (
  wellCount: { well: string; count: number }[],
  operatingVol: number,
  startingVol: number,
  stampVol: number
) => {
  for (const well of wellCount) {
    if (operatingVol - startingVol < stampVol * well.count) {
      return "Destination Wells exceed the volume limit";
    }
  }
};

const checkVSpinRange = (methodSettings: IPoolingMethodSettings) => {
  const vSpinSettingRange: {
    key: keyof IPoolingMethodSettings;
    displayValue: string;
    min: number;
    max: number;
  }[] = [
    {
      key: "spinParamPercent",
      displayValue: "Spin Param(%)",
      min: 1,
      max: 100,
    },
    { key: "spinTime", displayValue: "Spin Time", min: 4, max: 30 },
    {
      key: "spinAccel",
      displayValue: "Acceleration Settings",
      min: 1,
      max: 100,
    },
    {
      key: "spinDecel",
      displayValue: "Deceleration Settings",
      min: 1,
      max: 100,
    },
  ];

  const requiredMessage = [];
  for (const setting of vSpinSettingRange) {
    const settingValue = methodSettings[setting.key] as number;
    if (settingValue < setting.min || settingValue > setting.max) {
      requiredMessage.push(
        `${setting.displayValue} must be between ${setting.min} and ${setting.max}`
      );
    }
  }

  return requiredMessage;
};

const checkForRequiredMethodSettings = (
  methodSettings: IPoolingMethodSettings
) => {
  const methodSettingDisplayValues: Map<keyof IPoolingMethodSettings, string> =
    new Map([
      ["selectedSystem", "Selected System"],
      ["dissociationTime", "Dissociation Time"],
    ]);

  const requiredFieldMissing = (key: keyof IPoolingMethodSettings) =>
    methodSettings[key] === undefined ||
    ["", "-1", "0"].includes(methodSettings[key]!.toString());

  const requiredMessages = [...methodSettingDisplayValues.keys()]
    .filter((key) => requiredFieldMissing(key))
    .map((key) => `${methodSettingDisplayValues.get(key)} is a required field`);
  const requiredReagentMessages =
    checkDependentRequiredReagents(methodSettings);

  return [...requiredMessages, ...requiredReagentMessages];
};

const checkDependentRequiredReagents = (
  methodSettings: IPoolingMethodSettings
) => {
  type MethodSettingTuple = [
    requiredSetting: keyof IPoolingMethodSettings,
    dependentSetting: keyof IPoolingMethodSettings,
    fieldName: string
  ];
  const tuple: MethodSettingTuple[] = [
    [
      "washBeforeDissociation",
      "dissociationWashRGT",
      "Dissociation Wash Reagent",
    ],
    ["washAfterDissociation", "harvestWashRGT", "Harvest Wash Reagent"],
    ["reFeedSourceWells", "reFeedWellsRGT", "ReFeed Wells Reagent"],
  ];
  const requiredFieldMissing = (t: MethodSettingTuple) =>
    methodSettings[t[0]] && methodSettings[t[1]!]?.toString() === "0";
  const requiredReagentMessages = tuple
    .filter((t) => requiredFieldMissing(t))
    .map((t) => `${t[2]} is a required field`);

  return requiredReagentMessages;
};
