import { makeVar, useReactiveVar } from "@apollo/client";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import TreeView from "@mui/lab/TreeView";
import { animated, useSpring } from "@react-spring/web";
import { FolderTreeNode } from "features/Runs/components/FolderTree/TreeNode";
import {
  useRunFolderQueryProvider,
  useRunsFolderTreeState,
} from "features/Runs/state/hooks";
import {
  DirectoryFolder,
  DirectoryFragment,
  NodeType,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";
import styled from "styled-components";
import { GetTreeNodeKey } from "./helpers";

const StyledTreeView = styled(TreeView)`
  overflow: hidden;
  min-width: 250px;
  padding: 10px !important;
`;
const StyledExpandMore = styled(ExpandMore)`
  font-size: 25px !important;
`;
const StyledChevronRight = styled(ChevronRight)`
  font-size: 25px !important;
`;
export const DefaultFolderNode = { type: NodeType.Folder, id: 1 };
export const DefaultExpandedFolders = [DefaultFolderNode.id.toString()];
export const ExpandedFolders = makeVar<string[]>(DefaultExpandedFolders);

const SlideInAnimation: FC = ({ children }) => {
  const props = useSpring({
    transform: "translate3d(0px, 0, 0)",
    from: { transform: "translate3d(-40px, 0, 0)" },
  });
  return (
    <animated.div style={{ ...props, overflow: "none" }}>
      {children}
    </animated.div>
  );
};

export const RenderTree: FC<{ node: Maybe<DirectoryFolder> }> = ({ node }) => {
  return <FolderTreeNode key={GetTreeNodeKey(node)} node={node} />;
};
export function Tree({
  directory,
}: {
  directory: Array<Maybe<DirectoryFragment>>;
}): JSX.Element {
  const {
    runsFolderTreeState: { currentNode, folderSearch },
  } = useRunsFolderTreeState();
  const { loading } = useRunFolderQueryProvider();

  const handleToggle = useAsyncDebounce(
    (event: React.ChangeEvent<unknown>, nodeIds: string[]) => {
      if (
        !nodeIds.includes(
          GetTreeNodeKey(currentNode ?? { type: NodeType.Folder, id: 1 })
        )
      )
        nodeIds.push(GetTreeNodeKey(currentNode));
      ExpandedFolders(nodeIds);
    },
    250
  );
  const selected = useMemo(() => [GetTreeNodeKey(currentNode)], [currentNode]);
  const expanded = useReactiveVar(ExpandedFolders);

  const [show, toggle] = useState(true);

  useEffect(() => {
    toggle((value) => !value);
  }, [loading, folderSearch]);

  return (
    <SlideInAnimation key={show.toString()}>
      <StyledTreeView
        expanded={expanded}
        selected={selected}
        defaultCollapseIcon={<StyledExpandMore />}
        defaultExpandIcon={<StyledChevronRight />}
        onNodeToggle={handleToggle}
      >
        {directory.map((item) => (
          <RenderTree key={GetTreeNodeKey(item)} node={item} />
        ))}
      </StyledTreeView>
    </SlideInAnimation>
  );
}
