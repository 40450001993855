import {
  RunTaskFilterInput,
  RunTaskSortInput,
  SortEnumType,
  TaskStatusEnum,
} from "graphql/generated/schema-types";

export const defaultRunTaskStatusFilter = [
  TaskStatusEnum.Scheduled,
  TaskStatusEnum.InProgress,
];

export const defaultRunTaskFilter: RunTaskFilterInput = {
  and: [
    {
      status: {
        in: defaultRunTaskStatusFilter,
      },
    },
  ],
};

export const defaultSort: RunTaskSortInput[] = [
  { startTimeScheduled: SortEnumType.Asc },
];
