import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { connect } from "react-redux";
import { ActionCreators as UndoActionCreators } from "redux-undo";

interface Twelve24TCToolUndoRedoProps {
  canUndo?: boolean;
  canRedo?: boolean;
  onUndo?: any;
  onRedo?: any;
}

let Twelve24TCToolUndoRedo: React.FC<any> = ({
  canUndo,
  canRedo,
  onUndo,
  onRedo,
}: Twelve24TCToolUndoRedoProps) => (
  <div style={{ display: "flex" }}>
    <IconButton onClick={onUndo} disabled={!canUndo} size="large">
      <UndoIcon />
    </IconButton>
    <IconButton onClick={onRedo} disabled={!canRedo} size="large">
      <RedoIcon />
    </IconButton>
  </div>
);

const mapStateToProps = (state?: any) => ({
  canUndo: state.WorklistTools.Twelve24TCTool.past.length > 0,
  canRedo: state.WorklistTools.Twelve24TCTool.future.length > 0,
});

const mapDispatchToProps = {
  onUndo: UndoActionCreators.undo,
  onRedo: UndoActionCreators.redo,
};

Twelve24TCToolUndoRedo = connect(
  mapStateToProps,
  mapDispatchToProps
)(Twelve24TCToolUndoRedo);

export default Twelve24TCToolUndoRedo;
