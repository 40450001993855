import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
} from "@mui/material";
import { Maybe } from "graphql-tools";

interface IDFResultModalProps {
  isOpen: boolean;
  setShowResultsModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  results: Maybe<string>[] | null;
}

const IDFResultsModal = ({
  isOpen,
  setShowResultsModal,
  title,
  results,
}: IDFResultModalProps) => {
  const listItems = results?.map((result) => <ListItem>{result}</ListItem>);
  const onClick = () => setShowResultsModal(false);
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List>{listItems}</List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default IDFResultsModal;
