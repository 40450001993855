import { Rule } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadFile from "@mui/icons-material/UploadFile";
import { Button, Divider, FormControl, FormHelperText } from "@mui/material";
import { BackdropOpen } from "App";
import { useFormik } from "formik";
import {
  AppSuiteUser,
  useAddIdfUploadMutation,
  useValidateIdfUploadMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { GetUrl } from "helpers/get-url";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { DropZone } from "shared-components/DropZone";
import {
  ProjectsField,
  UserField,
} from "shared-components/FormFields/FormFields";
import styled from "styled-components/macro";
import IDFResultsModal from "../ModelsAndDialogs/IDFResultsModal";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.08);
`;

const StyledControl = styled(FormControl)`
  width: 400px;
  margin-top: 20px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  justify-content: center;
  align-items: ${({ justifyContent }: { justifyContent?: string }) =>
    justifyContent ?? ""};
  flex-wrap: wrap;
`;

const IDFFormScreen = () => {
  const [attachment, setAttachment] = useState<File[]>([]);
  const [validateIdfUploadMutation, { loading: validatingIDFForm }] =
    useValidateIdfUploadMutation();
  const [addIdfUploadMutation, { loading: uploadingIDFForm }] =
    useAddIdfUploadMutation();

  useEffect(() => {
    BackdropOpen({
      open: validatingIDFForm || uploadingIDFForm,
      header: validatingIDFForm ? "Validating IDF Form" : "Uploading IDF Form",
      subText: "Do not reload page",
      size: 60,
    });
  }, [validatingIDFForm, uploadingIDFForm]);

  const [showResultsModal, setShowResultsModal] = useState(false);
  const [resultsModalTitle, setResultsModalTitle] = useState<string>("");
  const [results, setResults] = useState<Maybe<string>[] | null>(null);
  const [user, setUser] = useState<Maybe<AppSuiteUser>>(null);

  const setResultsModalValues = (
    title: string,
    results: Maybe<string>[] | null
  ) => {
    setResultsModalTitle(title);
    setResults(results);
    setShowResultsModal(true);
  };

  const [selectedProject, setSelectedProject] = useState("");

  const backendUrl = GetUrl();
  const handleDownload = useMemo(
    () => () => {
      const url = `${backendUrl}/api/download/idfform`;
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = url;
      downloadLink.target = "_self";
      downloadLink.download = "latest-form.xlsx";
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    [backendUrl]
  );

  const { values, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      projectId: 0,
      userName: "",
      userId: 0,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleValidateFile = async () => {
    if (!selectedProject || selectedProject === "") {
      setResultsModalValues("Couldn't Upload", ["Please select a project"]);
      return;
    }
    if (!attachment[0]) {
      setResultsModalValues("Couldn't Validate", [
        "There was no file to validate.",
      ]);
      return;
    }

    const input = {
      name: attachment[0].name,
      file: attachment[0],
      projectCode: selectedProject,
      registeredBy: user?.userName || "",
      registeredById: user?.userId || -1,
    };

    try {
      const responseValidate = await validateIdfUploadMutation({
        variables: {
          input: input,
        },
      });
      const validateIdfUpload =
        responseValidate.data?.validateIdfUpload ?? false;
      setResultsModalTitle("Validation Results");
      if (validateIdfUpload)
        setResultsModalValues("Validation Results", validateIdfUpload);
      else
        setResultsModalValues("Validation Results", [
          "Unknown error occurred.",
        ]);
    } catch (err) {
      setResultsModalValues("Error", [
        "Error occurred during validation. Please try again.",
      ]);
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedProject || selectedProject === "") {
      setResultsModalValues("Couldn't Upload", ["Please select a project"]);
      return;
    }
    if (!user || user.userName === "") {
      setResultsModalValues("Couldn't Upload", ["Please select a user"]);
      return;
    }
    if (!attachment[0]) {
      setResultsModalValues("Couldn't Upload", ["No file attached"]);
      return;
    }

    const twentyMB = 1024 * 1024 * 20;

    if (attachment[0].size > twentyMB) {
      setResultsModalValues("Couldn't Upload", [
        `${attachment[0].name} is too large (20MB max)`,
      ]);
    }

    try {
      const input = {
        name: attachment[0].name,
        file: attachment[0],
        projectCode: selectedProject,
        registeredBy: user.userName,
        registeredById: user.userId,
      };
      const response = await addIdfUploadMutation({
        variables: {
          input: input,
        },
      });
      const idfUpload = response.data?.addIdfUpload ?? false;
      if (idfUpload) setResultsModalValues("Upload Results", idfUpload);
    } catch (err) {
      setResultsModalValues("Error", [
        "Error occurred during upload. Please try again.",
      ]);
    }
    setAttachment([]);
  };

  return (
    <Layout>
      <Section justifyContent="start">
        <StyledControl>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleDownload}
          >
            Download Latest IdfForm Template
          </Button>
        </StyledControl>
      </Section>

      <Divider />

      <form onSubmit={handleSubmit}>
        <Section justifyContent="start">
          <StyledControl>
            <ProjectsField
              id={"projects-combobox"}
              value={values.projectId}
              onChange={(event, newValue) => {
                if (newValue && !Array.isArray(newValue)) {
                  setFieldValue("projectId", newValue.projectId);
                  setSelectedProject(newValue.nyscfprefix || "");
                }
              }}
              variant="outlined"
              label={"Select Project"}
              helperText={
                errors.projectId && touched.projectId && errors.projectId
              }
              error={
                !!(errors.projectId && touched.projectId && errors.projectId)
              }
            />
            <FormHelperText>Select a project from the dropdown</FormHelperText>
          </StyledControl>

          <StyledControl>
            <UserField
              id="user-combobox"
              value={values.userId}
              filter={(val) => {
                return val?.groups?.some(
                  (group) =>
                    group?.groupName === "Project Management" ||
                    group?.groupName === "Software"
                );
              }}
              onChange={(event, newValue) => {
                const selectedUser = newValue as Maybe<AppSuiteUser>;
                setFieldValue("userName", selectedUser?.userName);
                setFieldValue("userId", selectedUser?.userId);
                setUser(selectedUser as AppSuiteUser);
              }}
              variant="outlined"
              label="Select User"
            />
            <FormHelperText>Select a user from the dropdown</FormHelperText>
          </StyledControl>

          <StyledControl>
            <DropZone
              attachments={attachment.slice(0, 1)}
              setAttachments={setAttachment}
              fileTypes=".xlsx"
            />
          </StyledControl>

          <StyledControl>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Rule />}
              onClick={handleValidateFile}
            >
              Validate IdfForm
            </Button>
          </StyledControl>

          <StyledControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<UploadFile />}
            >
              Validate & Upload IdfForm
            </Button>
          </StyledControl>
        </Section>
      </form>

      <IDFResultsModal
        isOpen={showResultsModal}
        setShowResultsModal={setShowResultsModal}
        title={resultsModalTitle}
        results={results}
      />
    </Layout>
  );
};

export default IDFFormScreen;
