import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { RunPageContextMenuHidden } from "features/Runs/components/Table/RunsTable";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  RunTask,
  TaskStatusEnum,
  useUpdateRunTasksMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { useGetRunActivitiesById } from "hooks/cacheAccessHooks";
import moment from "moment";
import React from "react";
import { Item, Menu, Submenu } from "react-contexify";
import { MenuItemEventHandler } from "react-contexify/lib/types";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";

const RescheduleTaskStatuses: Array<Maybe<TaskStatusEnum>> = [
  TaskStatusEnum.InProgress,
  TaskStatusEnum.Scheduled,
  TaskStatusEnum.Overdue,
];

export function DefaultRunTableContextMenu(): JSX.Element {
  const {
    runsModalDispatch,
    runsModalState: { selectedTasks },
  } = useRunsModalState();
  const [updateRunTasks] = useUpdateRunTasksMutation();
  const tasks = useGetRunActivitiesById(selectedTasks);
  const { isConfirmed } = useConfirm();

  const allTasksManual = (runTasks: RunTask[]) => {
    if (
      runTasks?.every(
        (rt) => rt.systemAssignedTo === 14 || rt.systemAssignedTo === null
      )
    )
      return true;
    return false;
  };

  const anyTasksOverdue = (runTasks: RunTask[]) => {
    if (runTasks?.some((rt) => rt.status === TaskStatusEnum.Overdue))
      return true;
    return false;
  };

  const anyFutureTasks = (runTasks: RunTask[]) => {
    const nowUTC = moment.utc().milliseconds(0);

    if (
      runTasks?.some((rt) => {
        const dtStartTimeUTC = moment.utc(rt.startTimeScheduled);
        return (
          dtStartTimeUTC.isAfter(nowUTC) &&
          rt.status === TaskStatusEnum.Scheduled
        );
      })
    ) {
      return true;
    }
    return false;
  };

  const updateTaskStatus = async (status: TaskStatusEnum) => {
    if (tasks === undefined || !tasks.length) return;

    if (tasks.length > 1 && status === TaskStatusEnum.InProgress) {
      return Alert({
        type: AlertType.ERROR,
        message: "You cannot mark multiple tasks as 'inProgress.'",
      });
    }

    if (
      !allTasksManual(tasks as RunTask[]) &&
      anyTasksOverdue(tasks as RunTask[]) &&
      status === TaskStatusEnum.InProgress
    ) {
      return Alert({
        type: AlertType.ERROR,
        message: "You cannot mark an 'Overdue' task 'In Progress.'",
      });
    }

    if (
      !allTasksManual(tasks as RunTask[]) &&
      anyFutureTasks(tasks as RunTask[])
    ) {
      return Alert({
        type: AlertType.ERROR,
        message:
          "You cannot mark a future task completed. Did you mean to cancel?",
      });
    }
    if (
      !allTasksManual(tasks as RunTask[]) &&
      (status === TaskStatusEnum.Completed ||
        status === TaskStatusEnum.InProgress)
    ) {
      const confirmed = await isConfirmed(
        `Are you SURE you want to set task(s) to ${status}? 
      This could affect other people's tasks and cannot be undone.`
      );

      if (!confirmed) {
        return;
      }
    }

    updateRunTasks({
      variables: {
        updateInputs: (tasks ?? []).map((task) => ({
          runActivityId: task?.runTaskId ?? 0,
          status,
          startTimeScheduled: task.startTimeScheduled,
          endTimeScheduled: task.endTimeScheduled,
          systemAssignedTo: task.systemAssignedTo,
        })),
      },
    }).then(() => {
      Alert({ type: AlertType.SUCCESS, message: "Status(es) Changed" });
    });
  };

  return (
    <Menu
      id="RunTaskTableContextMenu"
      onHidden={() => {
        setTimeout(() => RunPageContextMenuHidden(true), 200);
      }}
    >
      <Submenu label="Mark Selected As">
        <Item onClick={() => updateTaskStatus(TaskStatusEnum.Scheduled)}>
          Scheduled
        </Item>
        <Item onClick={() => updateTaskStatus(TaskStatusEnum.InProgress)}>
          In Progress
        </Item>
        <Item onClick={() => updateTaskStatus(TaskStatusEnum.Overdue)}>
          Overdue
        </Item>
        <Item onClick={() => updateTaskStatus(TaskStatusEnum.Cancelled)}>
          Cancelled
        </Item>
        <Item onClick={() => updateTaskStatus(TaskStatusEnum.Completed)}>
          Completed
        </Item>
      </Submenu>
      {RescheduleTaskStatuses.includes(tasks?.[0]?.status) && (
        <Item
          onClick={({ props }) => {
            runsModalDispatch({
              type: RunsModalActions.OPEN_MODAL,
              payload: {
                currentModal: RunTaskModals.RescheduleTasks,
                selectedTasks: (props as { selected: number[] }).selected ?? [],
              },
            });
          }}
        >
          Reschedule
        </Item>
      )}
      <Item
        onClick={({ props }: MenuItemEventHandler) => {
          runsModalDispatch({
            type: RunsModalActions.OPEN_MODAL,
            payload: {
              currentModal: RunTaskModals.EditRunActivity,
              selectedTasks: (props as { selected: number[] }).selected ?? [],
            },
          });
        }}
      >
        Edit
      </Item>
      <Item
        onClick={({ props }: MenuItemEventHandler) => {
          runsModalDispatch({
            type: RunsModalActions.OPEN_DELETE_DIALOG,
            payload: {
              selectedTasks: (props as { selected: number[] }).selected ?? [],
              deleteDialog: {
                open: true,
                typeName: "RunTask",
              },
            },
          });
        }}
      >
        Delete
      </Item>
    </Menu>
  );
}
