import { Divider, Paper } from "@mui/material";
import React from "react";
import { GeneralOptions } from "./GeneralOptions";
import { SelectionView } from "./SelectionView";
import PoolingNormalizationToolUndoRedo from "./UndoRedo";

export const PoolingNormalizationToolbar = () => {
  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: "wrap",
          marginLeft: "20px",
        }}
      >
        <GeneralOptions />
        <PoolingNormalizationToolUndoRedo />
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <SelectionView />
      </Paper>
    </React.Fragment>
  );
};
